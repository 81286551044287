// Number pototype update
import "./services/numberHelper";

import { auth } from "./services/firebase/init";

import "dialog-polyfill/dist/dialog-polyfill.css";
import "../css/app.css";

import { clientUserAuthenticatedEvent } from "./services/firebase/auth";

// display progress spinner for fun
import "./components/appIsLoading";
import { clientAppBootstrappedEvent } from "./components/appIsLoading";

// this happens once only, so make sure we create listener this way
document.addEventListener(
    clientUserAuthenticatedEvent.type,
    async () => {
        // bootstrap the application
        await import("./appBootstrap");

        const user = auth.currentUser;

        // enable user-id tracking in GA
        if (global.gtag)
            try {
                global.gtag("set", { user_id: user.uid });
                global.gtag("event", "page_view");
            } catch (error) {
                console.log(error);
                console.log("Unable to capture user UID.");
            }

        // Enable user tracking in Sentry
        if (global.Sentry) {
            global.Sentry.configureScope((scope) => {
                scope.setUser({ email: user.email });
            });
        }

        document.dispatchEvent(clientAppBootstrappedEvent);
    },
    { once: true }
);
